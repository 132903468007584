.SearchHeader-Top-Colume-Section {
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchHeader-Top-Colume-Manage-Heading {
  font-size: 1.125rem;
  font-weight: 600;
  color: #3e3e3e;
}

.SearchHeader-Top-Colume-section-leftside {
  display: flex;
}

.SearchHeader-Top-Colume-section-Employer-Button {
  padding: 0.375rem 0.3125rem;
  margin-left: 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #0f1e2b;
  background-color: #0f1e2b;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SearchHeader-Top-Colume-section-Employer-Button > span {
  color: #fff;
  font-size: 0.75rem;
  font-weight: normal;
}

.SearchHeader-Top-Colume-section-Employer-Button span:nth-child(1) {
  margin-right: 0.1875rem;
  font-size: 0.875rem !important;
}

.SearchHeader-Filter-colume {
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.SearchHeader-Filter-section {
  padding: 0.8125rem 1.25rem 0.75rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchHeader-Filter-section-search {
  font-size: 0.875rem;
  font-weight: normal;
  color: #6e6e6e;
}

.SearchHeader-Filter-section-search
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: unset;
}

.SearchHeader-Filter-section-search
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}

.SearchHeader-Filter-section-search
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: unset !important;
  box-shadow: unset !important;
}

.SearchHeader-Filter-section-search
  .ant-select-multiple
  .ant-select-selection-item {
  background: #fff;
  border: unset;
  font-size: 0.875rem;
  color: #1e1e1e;
  font-weight: normal;
}

.SearchHeader-Filter-section
  .ant-select-multiple
  .ant-select-selection-item-content {
  margin-right: 1.25rem;
}

.SearchHeader-Filter-section
  .ant-select
  .ant-select-focused
  .ant-select-multiple
  .ant-select-open
  .ant-select-show-search {
  width: 23.75rem !important;
}

.dropCss {
  width: 43.75rem !important;
}

/* .SearchHeader-Filter-section-left>span:nth-child(1) {
    border-radius: .25rem;
    border: solid .0625rem #007cf0;
    padding: .375rem 1.375rem;
    color: #007cf0;
    font-size: .875rem;
    font-weight: 600;
    margin-right: .625rem;
    cursor: pointer;
} */

/* .SearchHeader-Filter-section-left>span:nth-child(2) {
    border: solid .0625rem #e4e4e4;
    background-color: #fff;
    padding: .375rem;
    border-radius: .25rem;
    cursor: pointer;
} */

/* .SearchHeader-Filter-section-left .anticon {
  font-size: 1rem;
  color: #7e7e7e;
} */

.SearchHeader-Filter-section-left
  .ant-select-multiple
  .ant-select-selection-placeholder {
  color: #6e6e6e;
  font-size: 0.875rem;
  font-weight: normal;
}

.SearchHeader-Show-Results-section {
  border-bottom: solid 0.0625rem #e4e4e4;
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
}

.SearchHeader-Show-Results {
  font-size: 0.875rem;
  font-weight: bold;
  color: #9e9e9e;
}

.SearchHeader-Top-Colume-section-calender > span:nth-child(1) {
  font-size: 0.75rem;
  font-weight: normal;
  color: #7e7e7e;
}

.SearchHeader-Top-Colume-section-calender {
  display: flex;
  align-items: center;
}

.SearchHeader-Top-Colume-section-calender-range {
  width: 14.5rem;
  border-bottom: solid 1px #c4c4c4;
  margin-left: 0.5rem;
}

.SearchHeader-Top-Colume-section-calender-range .ant-picker-suffix {
  display: none;
}

.SearchHeader-Top-Colume-section-calender-range
  .ant-select-selection-placeholder {
  width: 0px !important;
  color: red;
}

.SearchHeader-Top-Colume-section-calender-range
  .ant-picker-range
  .ant-picker-active-bar {
  display: none;
}

.SearchHeader-Top-Colume-section-calender-range .ant-picker-range-separator {
  padding-left: unset !important;
}

.Drawer-footer {
  display: flex;
  justify-content: space-between;
}

.Drawer-footer > span:nth-child(1) {
  border: 0.0625rem solid transparent;
  padding: 0.75rem 3.125rem;
  font-size: 1rem;
  font-weight: 600;
  color: #7e7e7e;
  cursor: pointer;
}

.Drawer-footer > span:nth-child(2) {
  border: 0.0625rem solid #007cf0;
  background-color: #007cf0;
  border-radius: 0.25rem;
  padding: 0.75rem 2.125rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.search-header-Drower .ant-drawer-footer {
  padding: 0.9375rem !important;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.search-header-Drower .ant-drawer-title {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}

.search-header-Drower .ant-drawer-header {
  padding: 12px 15px !important;
}

.ant-drawer-footer .ant-drawer-body {
  padding: 20px 15px !important;
}

.search-header-Drower-Profession {
  font-size: 14px;
  font-weight: 600;
  color: #4e4e4e;
}

.search-header-Drower-content .ant-radio-group {
  display: flex !important;
  flex-direction: column;
  margin-top: 0.7813rem;
}

.search-header-Drower-radio-one-span {
  font-size: 0.875rem;
  color: #3e3e3e;
  margin-right: 0.5rem;
  font-weight: normal;
}

.search-header-Drower-radio-one-span-1 {
  font-size: 0.875rem;
  color: #8e8e8e;
  margin-left: 0.5rem;
}

.search-header-Drower-radio-one > span:nth-child(1) {
  margin-bottom: 0.6875rem;
}

.searchContainer-top {
  margin-bottom: 1.2813rem;
}

.search-header-Drower-content .ant-input {
  margin-top: 0.625rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #f0f0f0;
}

.SearchHeader-Filter-section-left
  .ant-dropdown
  .ant-dropdown-placement-bottomLeft {
  width: 250px !important;
}

.Search-header-Menu .ant-dropdown-menu-item {
  font-size: 0.75rem;
  font-weight: normal;
  text-align: left;
  color: #3e3e3e;
}

.search-header-Drower .ant-drawer-close {
  position: absolute;
  left: 93%;
  font-size: 1rem;
  color: #7e7e7e;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #118936;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ddeee2;
}

.search-header-Drower-radio-one .ant-radio-inner::after {
  background-color: #118936;
}

.search-header-Drower-radio-one .ant-radio-checked::after {
  border: 0.0625rem solid #118936;
}

.search-header-Drower-radio-one .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #118936;
}

.sbar-render-title {
  color: #1e1e1e;
  font-size: 0.75rem;
  font-weight: 600;
}

.sbar-render-title1 span {
  font-size: 0.75rem;
  color: #5e5e5e;
  padding-left: 0.75rem;
}

.Highlight_Seacrh_Value {
  color: #7e7e7e;
}

.NotLight_serach_Value {
  color: #1e1e1e;
  font-size: 0.75rem;
  font-weight: 600;
}

.filterCount {
  padding-left: 0.1875rem;
}

.Search-campaigns-input > .ant-input-affix-wrapper > input.ant-input {
  margin-top: unset !important;
}

.Search-campaigns-input .ant-input-affix-wrapper {
  margin-top: 0.625rem;
}

/* .SearchHeader-Filter-section .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid;
} */

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .Search-campaigns-input .ant-input-affix-wrapper {
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.15);
    border: solid 0.0625rem #d4d4d4;
    background-color: #fff;
    padding: 0.8125rem 0.6875rem;
  }

  .SearchHeader-Top-Colume-Manage-Heading {
    font-size: 1rem;
  }

  .SearchHeader-Top-Colume-section-calender > span:nth-child(1) {
    display: none;
  }

  .SearchHeader-Filter-colume {
    box-shadow: none;
    border-bottom: 0.0625rem solid #e4e4e4;
  }

  .SearchHeader-Filter-section-left {
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  .SearchHeader-Top-Colume-Section {
    padding: 0.625rem 0.9375rem;
  }

  .SearchHeader-Filter-section {
    gap: 0.625rem;
    justify-content: space-between;
    padding: 0rem 0.9375rem 0.625rem;
  }

  /* .SearchHeader-Filter-section-left>span:nth-child(1) {
        padding: .375rem .625rem;
        box-shadow: 0 0 .125rem 0 rgba(0, 0, 0, 0.25);
        border: none;
    } */

  /* .SearchHeader-Filter-section-left>span:nth-child(2) {
        box-shadow: 0 0 .125rem 0 rgba(0, 0, 0, 0.25);
        border: none;
    } */

  .SearchHeader-Filter-section-search
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  }

  .dropCss {
    width: 14.625rem !important;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  }

  .SearchHeader-Show-Results {
    font-size: 0.6875rem;
  }

  .SearchHeader-Show-Results-section {
    padding: 0.5rem 0.9375rem;
    display: flex;
    justify-content: space-between;
  }

  .SearchHeader-Show-Results-section span:nth-child(2) {
    font-size: 0.6875rem;
    font-weight: normal;
    color: #9e9e9e;
  }

  .search-header-Drower-content .ant-space-item {
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.15);
    border: solid 0.0625rem #d4d4d4;
    background-color: #fff;
    padding: 0.75rem;
  }

  .search-header-Drower-radio-one > span:nth-child(1) {
    margin-bottom: unset;
  }

  .search-header-Drower-content .ant-space-vertical {
    gap: 0.625rem !important;
  }

  .search-header-Drower-content .ant-radio-wrapper {
    width: 100%;
  }

  .search-header-Drower-content .ant-radio-wrapper > span:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .searchContainer-top {
    margin-bottom: 0.9375rem;
  }

  .search-header-Drower-content .ant-radio-group {
    margin-top: 0.9375rem;
  }

  .search-header-Drower .ant-drawer-body {
    padding: 1.25rem 0.9375rem 0.9375rem;
  }

  .search-header-Drower-radio-one-span-1 {
    font-size: 1rem;
    color: #5e5e5e;
  }

  .search-mobile-clear-dropdown {
    font-size: 14px;
    position: absolute;
    top: 0.625rem;
    color: #9e9e9e;
  }

  .search-header-Drower .ant-drawer-title {
    margin-left: 45%;
  }

  .Mobile-date-range {
    display: flex;
    flex-direction: column;
  }

  .Mobile-date-range .ant-picker-range {
    margin-top: 0.9375rem;
    padding-left: 0.75rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.15);
    border: solid 0.0625rem #d4d4d4;
    background-color: #fff;
  }

  .Mobile-date-range .ant-picker-range .ant-picker-active-bar {
    position: absolute;
  }

  /* /// */
  .Mobile-date-range
    .ant-picker-panels
    > *:first-child
    button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .Mobile-date-range
    .ant-picker-panels
    > *:first-child
    button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .Mobile-date-range.ant-picker-panels > *:last-child {
    display: none;
  }

  .Mobile-date-range.ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .Mobile-date-range.ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }

  .SearchHeader-Filter-section-search
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: unset !important;
    box-shadow: 0 0 0.125rem 0 rgb(0 0 0 / 25%) !important;
  }

  .Drawer-footer > span:nth-child(1) {
    display: none;
  }

  .Drawer-footer > span:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 17.5rem !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }

  .SearchHeader-Filter-section-search
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
  }

  .SearchHeader-Filter-section-search .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0.125rem 0 rgb(0 0 0 / 25%) !important;
    border: 0.0625rem solid rgba(0, 0, 0, 0.25) !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 1px;
    margin-right: 10px;
    order: -1;
  }

  .SearchHeader-Top-Colume-section-Employer-Button {
    max-height: 1.5rem;
  }
}
