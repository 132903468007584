.manageProfileCard {
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 0.625rem 0.625rem 0rem 0.625rem;
  padding: 0.625rem;
}
.manageProfileCard-e1 {
  display: flex;
  gap: 0.375rem;
}
.manageProfileCard-e1 .ant-checkbox-inner {
  width: 0.875rem;
  height: 0.875rem;
}
.manageProfileCard-e2 {
  display: flex;
  flex: 1;
  font-size: 0.75rem;
  flex-direction: column;
  gap: 0.3281rem;
}
.manageProfileCard-e2-main {
  display: flex;
  font-size: 0.75rem;
  gap: 0.3125rem;
  align-items: center;
}
.manageProfileCard-e1-Checkbox {
  margin-top: -0.0625rem;
}
.manageProfileCard-e2-image > img {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
}
.manageProfileCard-e2-name {
  max-width: 11.5625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #3e3e3e;
}

.manageProfileCard-e2-field {
  color: #1e1e1e;
}
.manageProfileCard-e2-date {
  font-size: 0.75rem;
  color: #7e7e7e;
}
.manageProfileCard-e3 {
  margin-left: auto;
  border-radius: 0.25rem;
  height: 1.4375rem;
  font-size: 0.75rem;
  padding: 0.1875rem 0.5rem;
}
.PaginationedItem {
  margin-left: auto;
  margin-bottom: 2.125rem;
}
.cardPluspagination {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  align-items: right;
}

.UserCharMob {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background: #007cf0;
}

.Active {
  background-color: #e3f1e7;
  color: #118936;
}
.Deleted,
.Deactivated {
  background-color: #fceaea;
  color: #db2828;
}
.Inactive {
  background-color: #fceaea;
  color: #db2828;
}
.Pending {
  background-color: #e4e4e4;
  color: #7e7e7e;
}
