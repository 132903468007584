.container-auth {
  /* just to place momentarily , will remove width in future  */
  width: 24.375rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  padding: 1.875rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}
.join-pop-up {
  display: grid;
  place-items: center;
  height: 100vh;
}
.Sign-into-SkillGigs {
  /* font-family: SFProText; */
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  color: #3e3e3e;
}
/* .ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  color:#888888;;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-items: center;
  top: 50%;
  width: 50%;
  flex: 0.05 0.1;
  border-top: 2px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
} */
.divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #888888;
  width: 30rem;
  font-size: 0.8125rem;
}
.divider:before,
.divider:after {
  content: "";
  -webkit-box-flex: 1.07;
  -ms-flex: 1.07 0.1;
  flex: 1.07 0.1;
  border-bottom: 0.0625rem solid;
  margin: auto;
  background-color: #dbdbdb;
  color: #dbdbdb;
}
.divider:before {
  margin-right: 0.625rem;
}
.divider:after {
  margin-left: 0.625rem;
}
.login-form-forgot {
  float: right;
  font-size: 0.75rem;
  color: #888888;
}
.login-form-button {
  margin: 1.25rem 0rem 1.25rem 0rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  border-radius: 0.25rem;
}
/* ant css */
.ant-btn-primary {
  background-color:#007cf0 !important;
  /* border: #dbdbdb !important; */
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529 !important;
}
.ant-form-item {
  margin-bottom: 0rem !important;
}
/* .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: red !important;
  border-right-width: 1px !important;
  outline: 10 !important;
  box-shadow: 0 0 0 0.0625rem red !important;
} */
/* ant css ends */
.admin_form_item_inside {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.admin_form_item_main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.admin_form_item_inside > div {
  height: 2.5rem;
}
.emailInputSignUp {
  height: 2.5rem !important;
  border-radius: 0.25rem !important;
  border: solid 0.0625rem #d4d4d4 !important;
  background-color: #fff !important;
}
.ForgottPassword {
  color: #007cf0;
  font-size:0.875rem;
  cursor: pointer;
}
.notValidInput {
  border-color: #db2828 !important;
  /* box-shadow: 0 0 0 0.0625rem #db2828 !important; */
}
.validInputCSS {
  border-color: #007cf0 !important;
  /* box-shadow: 0 0 0 0.0625rem #007cf0 !important; */
}
.passwordSignUp {
  height: 2.5rem !important;
  margin-top: 1rem;
  border-radius: 0.25rem !important;
  background-color: #fff !important;
  /* border: solid 0.0625rem #db2828 !important; */
  /* border: 1px solid #d4d4d4 !important; */
}
.notValidColor {
  color:  #db2828 !important;
  margin-top: 0.5rem;
  /* box-shadow: 0 0 0 0.0625rem red !important; */
}
.rememberBox {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
}
.remeberForgotCSS{
  color: #7e7e7e;
}
.terms-and-services {
  font-size: 0.75rem;
  margin-left: -0.2rem;
  color: black;
}
.signup-form .ant-tabs-tabpane {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  outline: none;
  /* width: 0; */
}
.signup-form .ant-tabs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* .ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--primaryColor);
  color: #e73959 !important;
} */
.signup-form .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 0.1875rem solid #e73959 !important;
  z-index: 2;
}
.signup-form .ant-form label {
  font-size: 0.75rem;
  color: #888888;
}
.signup-form .ant-tabs-tab-btn {
  color: #888888;
  text-shadow: 0 0 0.0156rem currentColor;
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: 500;
}

.signup-form .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black;
  text-shadow: 0 0 0.0156rem currentColor;
  font-weight: 500;
}
.show-password {
  position: fixed;
}
.login-form .ant-form-item-explain-error {
  color: #db2828 !important;
  margin-top: 0.5rem;
}

@media screen and (max-width: 56.25rem) {
  .container-auth {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin: 0;
    padding: 1.875rem 0.9375rem 0rem 0.9375rem;
    border: none;
    background-color: #fff;
  }
  .rememberBox {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
  }
  .admin_form_item_inside {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }
  .admin_form_item_main {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
  }
  .emailInputSignUp {
    height: 2.5rem !important;
    border-radius: 0.25rem !important;
    /* border: solid 0.0625rem #d4d4d4 !important; */
    background-color: #fff !important;
  }
  .passwordSignUp {
    height: 2.5rem !important;
  }
  .join-pop-up {
    display: flex;
    justify-content: center;
  }
}
